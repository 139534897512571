import React from 'react';

class Search extends React.Component {
    state = {
        isTop: true,
    };

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 150;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });
    }

    search() {
        const searchResults = document.getElementsByClassName('name-body')[1].innerHTML;
        // const searchResults = document.getElementsByClassName('name-body');
        const searchValue = document.getElementById('search-input').value;
        console.log(searchValue);
        console.log(searchResults);
    }

    render() {  
        return (
            <div className="search container-fluid p-0">
                <div className={this.state.isTop ? 'is-relative' : 'is-fixed'}>
                    <input type="text" placeholder="type here to highlight a rider" id="search-input" className="search-input" onKeyUp={this.search}></input>
                </div>
            </div>
        )
    }   
}

export default Search;