import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

class Table extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lbs: [],
            ones: [],
            twos: [],
            threes: [],
            fours: [],
            fives: [],
            sixs: [],
            sevens: [],
            eights: []
        };
    }
     
    componentDidMount() {
        fetch('http://localhost:3001/queries/all')
            .then(response => response.json())
            .then(lbs => this.setState({ lbs }));
        fetch('http://localhost:3001/queries/one')
            .then(response => response.json())
            .then(ones => this.setState({ ones }));
        fetch('http://localhost:3001/queries/two')
            .then(response => response.json())
            .then(twos => this.setState({ twos }));
        fetch('http://localhost:3001/queries/three')
            .then(response => response.json())
            .then(threes => this.setState({ threes }));
        fetch('http://localhost:3001/queries/four')
            .then(response => response.json())
            .then(fours => this.setState({ fours }));
        fetch('http://localhost:3001/queries/five')
            .then(response => response.json())
            .then(fives => this.setState({ fives }));
        fetch('http://localhost:3001/queries/six')
            .then(response => response.json())
            .then(sixs => this.setState({ sixs }));
        fetch('http://localhost:3001/queries/seven')
            .then(response => response.json())
            .then(sevens => this.setState({ sevens }));
        fetch('http://localhost:3001/queries/eight')
            .then(response => response.json())
            .then(eights => this.setState({ eights }));
    }
    
    render() {
        const { lbs, ones, twos, threes, fours, fives, sixs, sevens, eights } = this.state;
        return (
            <div className="container table-responsive">
                <table className="table">
                    <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
                        <Tab eventKey="all" title="All">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lbs.map((lb, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{lb.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{lb.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{lb.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-1" title="Level 1">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ones.map((one, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{one.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{one.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{one.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-2" title="Level 2">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {twos.map((two, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{two.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{two.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{two.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-3" title="Level 3">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {threes.map((three, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{three.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{three.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{three.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-4" title="Level 4">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fours.map((four, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{four.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{four.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{four.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-5" title="Level 5">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fives.map((five, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{five.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{five.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{five.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-6" title="Level 6">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sixs.map((six, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{six.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{six.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{six.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-7" title="Level 7">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sevens.map((seven, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{seven.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{seven.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{seven.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                        <Tab eventKey="level-8" title="Level 8">
                        <thead>
                            <tr>
                                <th className="rank-head" scope="col">Rank</th>
                                <th  className="name-head" scope="col">Name</th>
                                <th className="commits-head" scope="col">Commits</th>
                                <th className="points-head" scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eights.map((eight, i) => {
                                return (
                                    <tr>
                                        <th key={"rank-" + i} className="rank-body" scope="row">{i + 1}</th>
                                        <td key={"name-" + i} className="name-body">{eight.NOM}</td>
                                        <td key={"commits-" + i} className="commits-body">{eight.COMMITS}</td>
                                        <td key={"points-" + i} className="points-body">{eight.POINTS}</td>
                                    </tr>
                                )})
                            }
                        </tbody>
                        </Tab>
                    </Tabs>
                </table>
            </div>
        )
    }
}

export default Table;