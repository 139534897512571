import React from 'react';

const Footer = () => {
    return (
        <div className="container d-flex justify-content-center align-items-center">
            <a 
                className="legal-link"
                href="https://www.linkedin.com/in/tony-evariste-a025a6102/#"
                target="_blank"
                rel="noopener noreferrer" 
            >   
                Tony Evariste © Copyright 2020
            </a>
        </div>
    )
}

export default Footer;