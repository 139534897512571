import React from 'react';
import logo from '../img/logo.svg';
import Mode from './mode';
import Modal from './modal';

class NavBar extends React.Component {
  constructor() {
    super();

    this.state = {
      isShowingAbout: false
    }

    this.state = {
      isShowingLinks: false
    }

    this.state = {
      isShowingDonate: false
    }
}

openModalHandlerAbout = () => {
    this.setState({
        isShowingAbout: true
    });
}

closeModalHandlerAbout = () => {
    this.setState({
        isShowingAbout: false
    });
}

openModalHandlerLinks = () => {
  this.setState({
      isShowingLinks: true
  });
}

closeModalHandlerLinks = () => {
  this.setState({
      isShowingLinks: false
  });
}

openModalHandlerDonate = () => {
  this.setState({
      isShowingDonate: true
  });
}

closeModalHandlerDonate = () => {
  this.setState({
      isShowingDonate: false
  });
}

  render() {
    const AboutContent = <div><h4>What's the TrialsReporter bot ?</h4>
    <p>This is a bot (you don't say) you can use on the <a href="https://discord.gg/DkgT5Vx" target="_blank" rel="noopener noreferrer" >Trials Discord</a>, developed by <a href="https://twitter.com/champlow" target="_blank" rel="noopener noreferrer" >Chaaampy</a><br />
    It allows you to commit every ninja tracks you passed on Trials Fusion and Trials Rising<br />
    Feel free to follow <a href="https://twitter.com/gulzt" target="_blank" rel="noopener noreferrer" >Gulzt</a>, the bot developer, or <a href="https://twitter.com/trialsreporter" target="_blank" rel="noopener noreferrer" >TrialsReporter</a> on Twitter</p>
    <h4>How does TTTIER works ?</h4>
    <p>TTTIER add every ninja commits from every player who use it, and then calculate the total score for this rider, following this system :</p>
    <ul>
      <li>Ninja level 1 : 1 point</li>
      <li>Ninja level 2 : 2 points</li>
      <li>Ninja level 3 : 4 points</li>
      <li>Ninja level 4 : 8 points</li>
      <li>Ninja level 5 : 16 points</li>
      <li>Ninja level 6 : 32 points</li>
      <li>Ninja level 7 : 64 points</li>
      <li>Ninja level 8 : 128 points</li>
    </ul>
    <p>Please note that those leaderboards are <b>subjective</b> since many good players aren't using the bot (or not that much)</p>
    </div>
    const LinksContent = <div>
      <p>Here are some useful links :</p>
      <ul>
        <li><a href="https://discord.gg/DkgT5Vx" target="_blank" rel="noopener noreferrer" >Trials Discord</a></li>
        <li><a href="https://trials-france.fr" target="_blank" rel="noopener noreferrer" >Trials France</a></li>
        <li><a href="https://www.reddit.com/r/TrialsGames/" target="_blank" rel="noopener noreferrer" >Trials subreddit</a></li>
        <li><a href="https://forums.ubisoft.com/forumdisplay.php/556-RedLynx-Studio" target="_blank" rel="noopener noreferrer" >Official Trials forums</a></li>
      </ul>
    </div>
    const DonateContent = <div><p className="text-center">Wanna buy me a beer ? I like beer.</p>
    <a href="https://www.paypal.com/donate?hosted_button_id=EYL3AX944LM6U" target="_blank" rel="noopener noreferrer"  className="btn-donate pt-2 pb-2 pl-3 pr-3 d-flex align-items-center">DONATE</a></div>
    return (
      <nav className="navbar">
        <a
          className="logo-link"
          href="/"
          title="logo"
        >
          <img
            src={logo}
            className="logo"
            alt="tttier"
            height="34px"
            width="auto"
          />
        </a>
        <div className="d-flex justify-content-center align-items-center">
          <button
            onClick={this.openModalHandlerAbout}
            className="about-link"
            title="about"
          >
          </button>
          <button
            onClick={this.openModalHandlerLinks}
            className="links-link"
            title="useful links"
          >
          </button>
          <button
            onClick={this.openModalHandlerDonate}
            className="donate-link"
            title="donate"
          >
          </button>
          <Modal
            className="modal modal-about"
            show={this.state.isShowingAbout}
            close={this.closeModalHandlerAbout}
            title="About"
            content={AboutContent}
          />
          <Modal
            className="modal modal-links"
            show={this.state.isShowingLinks}
            close={this.closeModalHandlerLinks}
            title="Useful links"
            content={LinksContent}
          />
          <Modal
            className="modal modal-donate"
            show={this.state.isShowingDonate}
            close={this.closeModalHandlerDonate}
            title="Donate"
            content={DonateContent}
          />
          <Mode />
        </div>
      </nav>
    );
  }
}
  
export default NavBar;