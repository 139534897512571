import React from 'react';

class Mode extends React.Component {
    toggleClass = () => {
      const oldStyle = document.getElementById('app').className;
      const newClassName = oldStyle === 'light' ? 'dark' : 'light'
      document.getElementById('app').className =  newClassName
    }
  
    render() {
      return (
        <button
            title={"switch mode"}
            onClick={this.toggleClass}
            className="mode-link"
        >
        </button>
      );
    }
  }

export default Mode;
  