import React from 'react';
import './App.css';
import Header from './components/header';
import Intro from './components/intro';
import Search from './components/search';
import Table from './components/table';
import Footer from './components/footer';

function App() {
  return (
    <div className="light" id="app">
      <header className="header position-fixed container-fluid d-flex justify-content-center">
        <Header />
      </header>
      <main className="main container-fluid">
        <Intro />
        {/*<Search />*/}
        <Table />
      </main>
      <footer className="footer container-fluid">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
