import React from 'react';
import logo from '../img/logo.svg';

const Intro = () => {
    return (
        <div className="intro container-fluid p-4 d-flex align-items-center">
            <div className="container text-center">
                <h1>
                <img
                    src={logo}
                    className="logo"
                    alt="tttier"
                    height="50px"
                    width="auto"
                />
                </h1>
                <p id="test">official TrialsReporter leaderboards<br/><strong>Last DB update : 23/10/2020</strong></p>
            </div>
        </div>
    )
}

export default Intro;