import React from 'react';

const Modal = (props) => {
    return (
        <div>
            <div className="modal-overlay position-fixed"
                style={{
                    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
            </div>
            <div className="modal-wrapper position-fixed pr-3 pl-3 pb-1 pt-1"
                style={{
                    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
                <div className="modal-header pb-0">
                    <h3 className="text-center">
                        {props.title}
                    </h3>
                    <span className="close-modal-btn" onClick={props.close}>×</span>
                </div>
                <div className="modal-body">
                    {props.content}
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center">
                    <button className="btn-cancel pt-3 pb-3 pl-4 pr-4 d-flex align-items-center" onClick={props.close}>CLOSE</button>
                </div>
            </div>
        </div>
    )
}

export default Modal;